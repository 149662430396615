import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import TrackingDetails from '../TrackingDetails/TrackingDetails';
import { useTrackingInfo } from '../../hooks/useTrackingInfo';
import { FaSearch } from 'react-icons/fa';
import MainNavbar from '../MainNavbar/MainNavbar';
import './TrackingPage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function TrackingPage() {
  let [searchParams] = useSearchParams();
  const [trackingNumber, setTrackingNumber] = useState('');
  let trackingCode = searchParams.get('code');
  const { trackingDetails, error } = useTrackingInfo(trackingCode);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a tracking number in the URL parameters and set it
    const trackingParam = searchParams.get('code');
    if (trackingParam) {
      setTrackingNumber(trackingParam);
    }
  }, [searchParams]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    if (!trackingNumber.trim()) {
      // Validation failed: Empty input
      toast.error('Please enter a valid tracking number.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: `error-${new Date().getTime()}`
      });
    } else {
      // Validation success: Navigate to include tracking code in the URL
      navigate(`?code=${trackingNumber}`);
    }
  };

  // Show error alert if fetching tracking info failed
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: `error-${new Date().getTime()}`
      });
    }
  }, [error]);

  return (
    <>
      <MainNavbar />
      <Container className="mt-5 custom-form-container">
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail" className="position-relative">
                <div className="input-icon-container">
                  <Form.Control
                    type="text"
                    className="form-control-lg custom-input"
                    placeholder="Enter your tracking number here"
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    aria-label="Tracking Number"
                  />
                  <FaSearch className="position-absolute search-icon" style={{ left: '10px', top: '50%', transform: 'translateY(-50%)' }} />
                </div>
              </Form.Group>
              <Button variant="primary" type="submit" size="lg" className="custom-button">
                Track Package
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      {trackingDetails && <TrackingDetails trackingInfo={trackingDetails} />}
      <ToastContainer />
    </>
  );
}

export default TrackingPage;
