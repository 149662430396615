import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './MainNavbar.css';
import LogoImage from '../../assets/JIT-Logo-Cropped-Small.png'

const MainNavbar = () => {
  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container>
      <Navbar.Brand href="#home">
          <img
            src={LogoImage}
            className="d-inline-block align-top"
            alt="Logo Alt Text"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#tracking">Tracking</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
