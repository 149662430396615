import { useState, useEffect } from 'react';
import axios from 'axios';

export const useTrackingInfo = (trackingCode) => {
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTrackingDetails = async () => {
      if (!trackingCode) {
        return; // Exit if no tracking code is provided
      }
      
      setIsLoading(true); // Start loading

      try {
        const fullUrl = `${process.env.REACT_APP_API_URL}?code=${trackingCode}`;
        const response = await axios.get(fullUrl);
        
        // Check for non-standard error responses within a successful HTTP response
        if (response.data && response.data.data && response.data.data.result && response.data.data.result.error) {
          setError(response.data.data.result.error);
          setTrackingDetails(null); // Ensure no previous tracking details are displayed
        } else if (response.data && response.data.data && response.data.data.result.consignments) {
          // Assuming there's always only one consignment
          setTrackingDetails(response.data.data.result.consignments[0]);
        } else {
          // Handle unexpected response structure
          setError('Unexpected response structure from API');
          setTrackingDetails(null);
        }
      } catch (error) {
        // Handle HTTP error responses
        setError('Failed to fetch tracking details. Please try again later.');
        setTrackingDetails(null);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchTrackingDetails();
  }, [trackingCode]); // Re-run the effect if the trackingCode changes

  return { trackingDetails, isLoading, error };
};
