import React from 'react';
import Table from 'react-bootstrap/Table';

const TrackingDetails = ({ trackingInfo }) => {
    // Directly using trackingInfo assuming it to be the consignment object received.
    return (
        <div className="tracking-details-container">
            <h4>Tracking Details for Consignment ID: {trackingInfo?.consignmentId || 'N/A'}</h4>
            <Table striped bordered hover size="sm" className="tracking-table">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Iterate over the status array of the consignment */}
                    {trackingInfo?.status?.map((status, index) => (
                        <tr key={index}>
                            <td><span className="status-badge">{status.MasterStatusName}</span></td>
                            <td>{status.StatusTs}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TrackingDetails;
